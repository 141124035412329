<template>
  <div class="back_one">
    <div class="rules_div" @click="handleRules">
      <img src="~@/assets/img/invite_friends/botton_guize.png" class="img_guize" />
    </div>
    <div class="one_div">
      <div class="one_half" @click="handleImmediately">
        <img src="~@/assets/img/invite_friends/botton_liji.png" class="img_half" />
      </div>
      <div class="one_half" @click="handleFace">
        <img src="~@/assets/img/invite_friends/botton_mianduimian.png" class="img_half" />
      </div>
    </div>
  </div>
</template>
<script>
import { URL } from "@/api/http";
import { isIphone, callhandler } from "@/libs/util";
export default {
  props: ["recommendInfo"],
  data() {
    return {};
  },
  methods: {
    handleRules() {
      this.$router.push({
        path: "/invite_rules",
      });
    },
    handleImmediately() {
      if (isIphone) {
        callhandler("share", {
          //头部颜色
          title: "小v招新",
          introduction: "",
          imageUrl: "",
          url: URL + "invite_login?code=" + this.recommendInfo.code,
        });
      } else {
        window.APP.share(
          "小v招新",
          "",
          "",
          URL + "invite_login?code=" + this.recommendInfo.code
        );
      }
    },
    handleFace() {
      this.$router.push({
        path: "/face_invite",
        query: {
          invitation_code: this.recommendInfo.code,
        },
      });
    },
  },
};
</script>
